const smartBasketReducer = (state = false, action) => {
  switch (action.type) {
    case 'SMART_BASKET':
      return action.payload;
    default:
      return state;
  }
};

export default smartBasketReducer;
