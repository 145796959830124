import React from 'react';
import { ThemeProvider } from 'styled-components';
import Theme from './src/themes/Theme';
import GlobalStyles from './src/themes/GlobalStyle';
// import createStore from './src/state/createStore';
import { Provider } from "react-redux"
import { createStore } from "redux"
import reducer from './src/state/reducers';

export const wrapRootElement = ({ element }) => {
  const store = createStore(reducer);
  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        {element}
      </ThemeProvider>
    </Provider>
  );
};
