// import  React, {useContext } from "react"

import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
// import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"
// export const themeContext = useContext(ThemeManagerContext)

export const GlobalStyles = createGlobalStyle`
  ${reset}
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body, html {
    font-family: ${props => props.theme.fonts.main};
    // background-color: ${props => props.theme.colors.light2};
    // position: fixed;
    // top: 0;
  }
  `
  export default GlobalStyles
  
  //   background: ${props => props.theme.isDark ? props.theme.colors.light2 : props.theme.colors.dark2};
  




