import localeReducer from './locale'
import darkModeReducer from './darkMode'
import {combineReducers} from 'redux'
import hamburgerReducer from './hamburger'
import gdprReducer from './gdpr'
import paginationReducer from './pagination'
import smartBasketReducer from './smartBasket'

const rootReducer = combineReducers({
  localeString: localeReducer,
  isDark: darkModeReducer,
  hamburger: hamburgerReducer, 
  gdpr: gdprReducer, 
  smartBasket: smartBasketReducer, 
  pagination: paginationReducer, 
})

export default rootReducer
