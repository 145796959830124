export const Theme = {
  fonts: {
    main: "Muli, sans-serif",
    code: "Roboto mono, mono space",
  },
  navHeight: {
    singleLine: "4.5rem",
    sideBar: "12vw",
  },
  colors: {
    main1: "hsl(207,70%,59%)",
    main2: "hsl(207,70%,94%)",
    main3: "hsl(207,50%,45%)",
    dark1: "hsl(227,2%,12%)",
    dark2: "hsl(227,2%,26%)",
    dark2alpha: "rgb(65,66,68,0.95)",
    dark3: "#666666",
    light1: "hsl(0,0%,97%)",
    light2: "#e7ffff",
    light2alpha: "rgb(231,255,255,0.9)",
    ed: "#ededed",
    lightOrange: "#FF8623",
    darkOrange: "#C15700",
    gabrielle_light: "#e7ffff",
  },
  breakpoints: {
    mobile: "only screen and (max-width: 45rem)",
    tablet: "only screen and (max-width: 60rem)",
    tabletLandscape: "only screen and (max-width: 62rem)",
  },
  spacings: {
    xxSmall: ".25rem",
    xSmall: ".5rem",
    small: "1rem",
    medium: "2rem",
    large: "3rem",
    xLarge: "0.5rem",
    xxLarge: "6rem",
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.2s ease",
  },
  shadows: {
    shadow1: "0px 5px 20px 0 rgba(30,30,31,0.55)",
    shadow2: "0px 5px 20px 0 rgba(210,210,211,0.15)"
  },
}
export default Theme